import revive_payload_client_4sVQNw7RlN from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/injective-explorer/injective-explorer/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/injective-explorer/injective-explorer/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/injective-explorer/injective-explorer/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_C3JbV6BOJo from "/home/runner/work/injective-explorer/injective-explorer/node_modules/@injectivelabs/nuxt-bugsnag/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/injective-explorer/injective-explorer/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/injective-explorer/injective-explorer/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import buffer_1qFfPJo2Yp from "/home/runner/work/injective-explorer/injective-explorer/node_modules/.c12/github_InjectiveLabs_injective_CnbD4kuJy2/nuxt-config/buffer.ts";
import tracking_LWwQKiKkKx from "/home/runner/work/injective-explorer/injective-explorer/node_modules/.c12/github_InjectiveLabs_injective_CnbD4kuJy2/plugins/tracking.ts";
import handlers_oZWLY9pUCB from "/home/runner/work/injective-explorer/injective-explorer/plugins/handlers.ts";
import store_9xNuDHGAVU from "/home/runner/work/injective-explorer/injective-explorer/plugins/store.ts";
import vee_validate_KcKlKmvCrJ from "/home/runner/work/injective-explorer/injective-explorer/plugins/vee-validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_C3JbV6BOJo,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  buffer_1qFfPJo2Yp,
  tracking_LWwQKiKkKx,
  handlers_oZWLY9pUCB,
  store_9xNuDHGAVU,
  vee_validate_KcKlKmvCrJ
]